/* Chrome, Safari, Edge, Opera */
.simple-date-picker input::-webkit-outer-spin-button,
.simple-date-picker input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  box-shadow: none;
}

/* Firefox */
.simple-date-picker input[type="number"] {
  -moz-appearance: textfield;
  box-shadow: none;
  text-align: center;
}
